import React from 'react';
import { ListItemButton, ListItemText, Collapse } from '@mui/material';
import iconAccordion from '../../../assets/icons/icon-arrow.svg';
import BrandLogoTraitManager from '../../../layouts/EmailBuilder/components/GrapesJSComponents/traits/CustomTraitManager/BrandLogoTraitManager';
import TypographyTraitManager from '../../../layouts/EmailBuilder/components/GrapesJSComponents/traits/CustomTraitManager/TypographyTraitManager';
import ButtonTraitManager from '../../../layouts/EmailBuilder/components/GrapesJSComponents/traits/CustomTraitManager/ButtonTraitManager';

const FormBranding = ({
  htmlContent,
  updateHtmlContent,
  buttonTraits,
  setButtonTraits,
  typegraphyTraits,
  setTypegraphyTraits,
  brandLogo,
  setBrandLogo,
  brandLogoLink,
  setBrandLogoLink,
  brandLogoWidth,
  setBrandLogoWidth,
  brandLogoPadding,
  setBrandLogoPadding,
  brandLogoBorderRadius,
  setBrandLogoBorderRadius,
}) => {
  const [openBrandLogo, setOpenBrandLogo] = React.useState(true);
  const [openTypography, setOpenTypography] = React.useState(false);
  const [openButton, setOpenButton] = React.useState(false);

  const handleBrandLogo = () => {
    setOpenBrandLogo(!openBrandLogo);
  };

  const handleTypography = () => {
    setOpenTypography(!openTypography);
  };

  const handleButton = () => {
    setOpenButton(!openButton);
  };

  const parseHtml = (htmlString) => {
    const parser = new DOMParser();
    return parser.parseFromString(htmlString, 'text/html');
  };

  const updateHtml = (htmlDoc, selector, styleObject) => {
    const elements = htmlDoc.querySelectorAll(selector);
    elements.forEach((element) => {
      Object.keys(styleObject).forEach((style) => {
        element.style[style] = styleObject[style];
      });
    });
  };

  const handleTypographyChange = (styleObject) => {
    const htmlDoc = parseHtml(htmlContent);
    updateHtml(htmlDoc, '.text-block', styleObject);
    updateHtmlContent(htmlDoc.documentElement.outerHTML);
  };

  const handleButtonChange = (styleObject) => {
    const htmlDoc = parseHtml(htmlContent);
    updateHtml(htmlDoc, '.button-block .button', styleObject);
    updateHtmlContent(htmlDoc.documentElement.outerHTML);
  };

  const handleLogoChange = (styleObject) => {
    const htmlDoc = parseHtml(htmlContent);
    updateHtml(htmlDoc, '.image-block', styleObject);
    updateHtmlContent(htmlDoc.documentElement.outerHTML);
  };

  const handleLogoChangeParent = (styleObject) => {
    const htmlDoc = parseHtml(htmlContent);
    updateHtml(htmlDoc, '.image-container', styleObject);
    updateHtmlContent(htmlDoc.documentElement.outerHTML);
  };

  return (
    <div className="FormBrandingDrawer">
      <ListItemButton onClick={handleBrandLogo} className="Item">
        <ListItemText primary="Brand Logo" />
        {openBrandLogo ? (
          <img src={iconAccordion} className="accordion-up arrow" />
        ) : (
          <img src={iconAccordion} className="arrow" />
        )}
      </ListItemButton>
      <Collapse in={openBrandLogo} timeout="auto" unmountOnExit>
        {/* <BrandLogoTraitManager
          brandLogo={brandLogo}
          setBrandLogo={setBrandLogo}
          brandLogoLink={brandLogoLink}
          setBrandLogoLink={setBrandLogoLink}
          brandLogoWidth={brandLogoWidth}
          setBrandLogoWidth={setBrandLogoWidth}
          brandLogoPadding={brandLogoPadding}
          setBrandLogoPadding={setBrandLogoPadding}
          brandLogoBorderRadius={brandLogoBorderRadius}
          setBrandLogoBorderRadius={setBrandLogoBorderRadius}
          onChange={handleLogoChange}
          onParentChange={handleLogoChangeParent}
          htmlContent={htmlContent}
          updateHtmlContent={updateHtmlContent}
        /> */}
      </Collapse>

      <ListItemButton onClick={handleTypography} className="Item">
        <ListItemText primary="Typography" />
        {openTypography ? (
          <img src={iconAccordion} className="accordion-up arrow" />
        ) : (
          <img src={iconAccordion} className="arrow" />
        )}
      </ListItemButton>
      <Collapse in={openTypography} timeout="auto" unmountOnExit>
        <TypographyTraitManager
          typegraphyTraits={typegraphyTraits}
          setTypegraphyTraits={setTypegraphyTraits}
          onChange={handleTypographyChange}
        />
      </Collapse>

      {/* Button Section */}
      <ListItemButton onClick={handleButton} className="Item">
        <ListItemText primary="Button & Links" />
        {openButton ? (
          <img src={iconAccordion} className="accordion-up arrow" />
        ) : (
          <img src={iconAccordion} className="arrow" />
        )}
      </ListItemButton>
      <Collapse in={openButton} timeout="auto" unmountOnExit>
        <ButtonTraitManager
          buttonTraits={buttonTraits}
          setButtonTraits={setButtonTraits}
          onChange={handleButtonChange}
        />
      </Collapse>
    </div>
  );
};

export default FormBranding;
