import React from 'react';
import {List, ListItemButton, ListItemText, Collapse} from '@mui/material';
import {useSelector} from 'react-redux';
import iconAccordion from '../../../../assets/icons/icon-arrow.svg';
import BrandLogoTraitManager from '../GrapesJSComponents/traits/CustomTraitManager/BrandLogoTraitManager';
import TypographyTraitManager from '../GrapesJSComponents/traits/CustomTraitManager/TypographyTraitManager';
import ButtonTraitManager from '../GrapesJSComponents/traits/CustomTraitManager/ButtonTraitManager';
import FooterTraitManager from '../GrapesJSComponents/traits/CustomTraitManager/FooterTraitManager';
import ColorTraitManager from '../GrapesJSComponents/traits/CustomTraitManager/ColorTraitManager';
import SocialTraitManager from '../GrapesJSComponents/traits/CustomTraitManager/SocialTraitManager';

const OverlayDrawer = ({
 htmlContent,
 updateHtmlContent,
 buttonTraits,
 setButtonTraits,
 typegraphyTraits,
 setTypegraphyTraits,
 setColorTraits,
 socialTraits,
 setSocialTraits,
 footerTraits,
 setFooterTraits,
 brandLogo,
 setBrandLogo,
 brandLogoLink,
 setBrandLogoLink,
 brandLogoWidth,
 setBrandLogoWidth,
 brandLogoPadding,
 setBrandLogoPadding,
 brandLogoBorderRadius,
 setBrandLogoBorderRadius
}) => {
 const [openBrandLogo, setOpenBrandLogo] = React.useState(false);
 const [openColors, setOpenColors] = React.useState(false);
 const [openTypography, setOpenTypography] = React.useState(false);
 const [openButton, setOpenButton] = React.useState(false);
 const [openSocialMedia, setOpenSocialMedia] = React.useState(false);
 const [openFooter, setOpenFooter] = React.useState(false);
 const drawerOpen = useSelector(state => state.isSidebar);
 const brandColors = useSelector(state => state.brandColors);

  const handleBrandLogo = () => {
    setOpenBrandLogo(!openBrandLogo);
  };

  const handleColors = () => {
    setOpenColors(!openColors);
  };
  const handleTypography = () => {
    setOpenTypography(!openTypography);
  };
  const handleButton = () => {
    setOpenButton(!openButton);
  };
  const handleSocialMedia = () => {
    setOpenSocialMedia(!openSocialMedia);
  };
  const handleFooter = () => {
    setOpenFooter(!openFooter);
  };

  const colors = <List component="div" disablePadding></List>;

  const typography = <List component="div" disablePadding></List>;

  const button = <List component="div" disablePadding></List>;

  const socialMedia = <List component="div" disablePadding></List>;

 const footer = <List component="div" disablePadding></List>;
 const parseHtml = htmlString => {
  const parser = new DOMParser();
  return parser.parseFromString(htmlString, 'text/html');
 };

 const updateHtml = (htmlDoc, selector, styleObject) => {
  const elements = htmlDoc.querySelectorAll(selector);
  elements.forEach(element => {
   Object.keys(styleObject).forEach(style => {
    element.style[style] = styleObject[style];
   });
  });
 };

 const handleTypographyChange = styleObject => {
  const htmlDoc = parseHtml(htmlContent);
  updateHtml(htmlDoc, '.text-block', styleObject);
  updateHtmlContent(htmlDoc.documentElement.outerHTML);
 };

 const handleButtonChange = styleObject => {
  const htmlDoc = parseHtml(htmlContent);
  updateHtml(htmlDoc, '.button-block .button', styleObject);
  updateHtmlContent(htmlDoc.documentElement.outerHTML);
 };

 const handleLogoChange = styleObject => {
  const htmlDoc = parseHtml(htmlContent);
  updateHtml(htmlDoc, '.image-block', styleObject);
  updateHtmlContent(htmlDoc.documentElement.outerHTML);
 };

 const handleLogoChangeParent = styleObject => {
  const htmlDoc = parseHtml(htmlContent);
  updateHtml(htmlDoc, '.image-container', styleObject);
  updateHtmlContent(htmlDoc.documentElement.outerHTML);
 };

 const drawer = (
  <div className="OverlayDrawer">
   <ListItemButton
    onClick={handleBrandLogo}
    style={{
     backgroundColor: openBrandLogo ? '#EBEBEB' : '#FFFFFF'
    }}
    className="Item"
   >
    <ListItemText
     primary="Brand Logo"
     sx={{display: drawerOpen ? 'block' : 'none'}}
    />
    {drawerOpen &&
     (openBrandLogo ? (
      <img src={iconAccordion} className="accordion-up arrow" />
     ) : (
      <img src={iconAccordion} className="arrow" />
     ))}
   </ListItemButton>
   <Collapse
    in={openBrandLogo && drawerOpen}
    timeout="auto"
    unmountOnExit
    sx={{p: '16px', borderBottom: '1px solid #dfdded'}}
   >
    <BrandLogoTraitManager
     brandLogo={brandLogo}
     setBrandLogo={setBrandLogo}
     brandLogoLink={brandLogoLink}
     setBrandLogoLink={setBrandLogoLink}
     brandLogoWidth={brandLogoWidth}
     setBrandLogoWidth={setBrandLogoWidth}
     brandLogoPadding={brandLogoPadding}
     setBrandLogoPadding={setBrandLogoPadding}
     brandLogoBorderRadius={brandLogoBorderRadius}
     setBrandLogoBorderRadius={setBrandLogoBorderRadius}
     onChange={handleLogoChange}
     onParentChange={handleLogoChangeParent}
     htmlContent={htmlContent}
     updateHtmlContent={updateHtmlContent}
    />
   </Collapse>
   <ListItemButton
    onClick={handleColors}
    style={{
     backgroundColor: openColors ? '#EBEBEB' : '#FFFFFF'
    }}
    className="Item"
   >
    <ListItemText
     primary="Colors"
     sx={{display: drawerOpen ? 'block' : 'none'}}
    />
    {drawerOpen &&
     (openColors ? (
      <img src={iconAccordion} className="accordion-up arrow" />
     ) : (
      <img src={iconAccordion} className="arrow" />
     ))}
   </ListItemButton>
   <Collapse
    in={openColors && drawerOpen}
    timeout="auto"
    unmountOnExit
    sx={{p: '16px', borderBottom: '1px solid #dfdded'}}
   >
    <ColorTraitManager
     brandColors={brandColors}
     setColorTraits={setColorTraits}
    />
   </Collapse>
   <ListItemButton
    onClick={handleTypography}
    style={{
     backgroundColor: openTypography ? '#EBEBEB' : '#FFFFFF'
    }}
    className="Item"
   >
    <ListItemText
     primary="Typography"
     sx={{display: drawerOpen ? 'block' : 'none'}}
    />
    {drawerOpen &&
     (openTypography ? (
      <img src={iconAccordion} className="accordion-up arrow" />
     ) : (
      <img src={iconAccordion} className="arrow" />
     ))}
   </ListItemButton>
   <Collapse
    in={openTypography && drawerOpen}
    timeout="auto"
    unmountOnExit
    sx={{p: '16px', borderBottom: '1px solid #dfdded'}}
   >
    <TypographyTraitManager
     typegraphyTraits={typegraphyTraits}
     setTypegraphyTraits={setTypegraphyTraits}
     onChange={handleTypographyChange}
    />
   </Collapse>
   <ListItemButton
    onClick={handleButton}
    style={{
     backgroundColor: openButton ? '#EBEBEB' : '#FFFFFF'
    }}
    className="Item"
   >
    <ListItemText
     primary="Button"
     sx={{display: drawerOpen ? 'block' : 'none'}}
    />
    {drawerOpen &&
     (openButton ? (
      <img src={iconAccordion} className="accordion-up arrow" />
     ) : (
      <img src={iconAccordion} className="arrow" />
     ))}
   </ListItemButton>
   <Collapse
    in={openButton && drawerOpen}
    timeout="auto"
    unmountOnExit
    sx={{p: '16px', borderBottom: '1px solid #dfdded'}}
   >
    <ButtonTraitManager
     buttonTraits={buttonTraits}
     setButtonTraits={setButtonTraits}
     onChange={handleButtonChange}
    />
   </Collapse>
   <ListItemButton
    onClick={handleSocialMedia}
    style={{
     backgroundColor: openSocialMedia ? '#EBEBEB' : '#FFFFFF'
    }}
    className="Item"
   >
    <ListItemText
     primary="Social Media Links"
     sx={{display: drawerOpen ? 'block' : 'none'}}
    />
    {drawerOpen &&
     (openSocialMedia ? (
      <img src={iconAccordion} className="accordion-up arrow" />
     ) : (
      <img src={iconAccordion} className="arrow" />
     ))}
   </ListItemButton>
   <Collapse
    in={openSocialMedia && drawerOpen}
    timeout="auto"
    unmountOnExit
    sx={{p: '16px', borderBottom: '1px solid #dfdded'}}
   >
    <SocialTraitManager
     htmlContent={htmlContent}
     updateHtmlContent={updateHtmlContent}
     socialTraits={socialTraits}
     setSocialTraits={setSocialTraits}
    />
   </Collapse>
   <ListItemButton
    onClick={handleFooter}
    style={{
     backgroundColor: openFooter ? '#EBEBEB' : '#FFFFFF'
    }}
    className="Item"
   >
    <ListItemText
     primary="Footer"
     sx={{display: drawerOpen ? 'block' : 'none'}}
    />
    {drawerOpen &&
     (openFooter ? (
      <img src={iconAccordion} className="accordion-up arrow" />
     ) : (
      <img src={iconAccordion} className="arrow" />
     ))}
   </ListItemButton>
   <Collapse
    in={openFooter && drawerOpen}
    timeout="auto"
    unmountOnExit
    sx={{p: '16px', borderBottom: '1px solid #dfdded'}}
   >
    <FooterTraitManager
     htmlContent={htmlContent}
     updateHtmlContent={updateHtmlContent}
     footerTraits={footerTraits}
     setFooterTraits={setFooterTraits}
    />
   </Collapse>
  </div>
 );
 return <>{drawer}</>;
};

export default OverlayDrawer;
