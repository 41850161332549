import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import iconCross from '../../assets/icons/icon-cross.svg';
import iconSearch from '../../assets/icons/icon-search.svg';

const SearchBar = ({ onSearch, onClear }) => {
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (searchValue.length > 2) {
      setLoading(true);
      const delayDebounceFn = setTimeout(() => {
        Promise.resolve(onSearch(searchValue)).finally(() => setLoading(false));
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setLoading(false);
    }
  }, [searchValue, onSearch]);

  const handleClearSearch = () => {
    setSearchValue('');
    setLoading(false);
    onClear();
  };

  return (
    <TextField
      placeholder="Search..."
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      InputProps={{
        sx: {
          padding: '8px 12px', 
        },
        startAdornment: (
          <InputAdornment position="start">
            <img src={iconSearch} alt="search here" height={20} width={20}/>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {loading ? (
              <CircularProgress size={24} />
            ) : searchValue ? (
              <button className='btn common-btn' onClick={handleClearSearch}>
                <img src={iconCross} alt="clear search" height={20} width={20}/>
              </button>
            ) : null}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;
