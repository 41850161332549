import React from 'react';
import iconBack from '../../../assets/icons/icon-back.svg';

const QuickLinks = () => {
  return (
    <div className="quick-link-container">
      <h3>Quick Links</h3>
      <div className="links">
        <div className="link">
          <span>Contacts</span>
          <img src={iconBack} alt="Go to Link" />
        </div>
        <div className="link">
          <span>Email Campaigns</span>
          <img src={iconBack} alt="Go to Link" />
        </div>
        <div className="link">
          <span>Carts</span>
          <img src={iconBack} alt="Go to Link" />
        </div>
        <div className="link">
          <span>Automations</span>
          <img src={iconBack} alt="Go to Link" />
        </div>
      </div>
    </div>
  );
};

export default QuickLinks;
