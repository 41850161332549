import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import iconCopy from '../../../assets/icons/icon-copy.svg';
import iconCross from '../../../assets/icons/icon-cross.svg';

const FormDataComponent = ({
  heading,
  subheading,
  formTitle,
  formOptions = [],
  showFormOptions = false,
  onAddOption,
  onDeleteOption,
  htmlContent,
  updateHtmlContent,
}) => {
    const updateHtmlContentWithDOM = (newHeading, newSubheading) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
    
        // Update heading
        const headingElement = doc.querySelector('.content-header');
        if (headingElement) {
          headingElement.textContent = newHeading;
        }
    
        // Update subheading
        const subheadingElement = doc.querySelector('.content-text');
        if (subheadingElement) {
          subheadingElement.textContent = newSubheading;
        }
    
        // Convert back to string
        const updatedHtml = doc.body.innerHTML;
        updateHtmlContent(updatedHtml);
      };
      const handleHeadingChange = (e) => {
        const newHeading = e.target.value;
        updateHtmlContentWithDOM(newHeading, subheading);
      };
    
      const handleSubheadingChange = (e) => {
        const newSubheading = e.target.value;
        updateHtmlContentWithDOM(heading, newSubheading);
      };
  return (
    <div className="form-data-container">
      <div className="form-data-fields">
        <h4>Heading</h4>
        <input class="input-trait" defaultValue={heading} onChange={handleHeadingChange} />

        <span>{'Use {{company_name}} merge tag to show company name'}</span>
      </div>

      <div className="form-data-fields">
        <h4>Subheading</h4>
        <input class="input-trait" defaultValue={subheading} onChange={handleSubheadingChange} />
        <span>{'Use {{company_name}} merge tag to show company name'}</span>
      </div>

      {formTitle && (
        <div className="form-data-fields">
          <h4>Form Title</h4>
          <input class="input-trait" />
        </div>
      )}

      {showFormOptions && (
        <div className="form-options">
          <h4>Form Options</h4>
          {formOptions.map((option, index) => (
            <TextField
              key={index}
              value={option.label}
              InputProps={{
                sx: {
                  padding: '8px 12px',
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      className="input-icon"
                      src={iconCopy}
                      alt="copy"
                      height={20}
                      width={20}
                    />
                    <img
                      className="input-icon"
                      onClick={() => onDeleteOption(index)}
                      src={iconCross}
                      alt="copy"
                      height={20}
                      width={20}
                    />
                  </InputAdornment>
                ),
              }}
            />
          ))}
          <div>
            <button className="btn btn-primary-color" onClick={onAddOption}>
              + Add Option
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormDataComponent;
